<template>
    <div id="app">
        <el-container >
            <el-header height="90px" class="padding-override">
                <el-row>
                    <el-col :offset="0" :span="10" align="center" >
                        <img class="logo" :src="logo" />
                    </el-col>
                    <el-col :span="14" >
                        <!-- 导航栏 -->
                        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
                                 @select="handleSelect" >
                            <el-menu-item index="1" class="item">首页</el-menu-item>
                            <el-menu-item index="2" class="item">解决方案</el-menu-item>
                            <el-menu-item index="6" class="item">设计作品</el-menu-item>
                            <el-menu-item index="3" class="item">知了集市</el-menu-item>
                            <el-menu-item index="4" class="item">关于我们</el-menu-item>
                            <el-menu-item index="5" class="item">联系我们</el-menu-item>
                        </el-menu>
                    </el-col>
                </el-row>

            </el-header>
            <el-main class="padding-override">
                <router-view/>
            </el-main>
            <el-footer height="160px"  class="padding-override" v-if="isShowFooter">
              <div class="footerBox">
                <ul class="footerList">
                  <li class="logoItem">
                    <img class="logoImg" src="./assets/imgs/logonative.png">
                    <P class="subTitle" style="color: #dfdede;text-align: center;">您身边的技术合伙人</P>
                  </li>
                  <li>
                    <p class="title">我们</p>
                    <span @click="handleSelect('4')" class="subTitle cursorPoint">关于我们</span>
                    <span @click="handleSelect('5')" class="subTitle cursorPoint">联系我们</span>
                  </li>
                  <li>
                    <p class="title">方案</p>
                    <span  @click="handleSelect('2')" class="subTitle cursorPoint">网站</span>
                    <span  @click="handleSelect('2')" class="subTitle cursorPoint">移动App</span>
                  </li>
                  <li>
                    <p class="title">知了集市</p>
                    <span @click="handleSelect('3')" class="subTitle cursorPoint">小程序</span>
                  </li>
                  <!--<li>-->
                    <!--<p class="title">发现</p>-->
                    <!--<span @click="handleSelect('2')" class="subTitle">常见问题</span>-->
                  <!--</li>-->
                  <li>
                    <p class="title">联系我们</p>
                    <p class="subTitle">地址: 广州市天河区棠东东路5号远洋创意园B127</p>
                    <p class="subTitle">电话: 18027188617(吕)</p>
                  </li>
                  <li>
                    <img class="codeImg" src="./assets/imgs/home/code.png">
                    <p class="subTitle">销售联系方式</p>
                  </li>
                  <li>
                    <img class="codeImg" src="./assets/imgs/home/wxcode.jpg">
                    <p class="subTitle">官方公众号</p>
                  </li>
                </ul>
                <p class="recordeText">Copyright©2016-2018 广州都灵源链信息科技有限公司 All Rights Reserved. 粤ICP备16101632号-1</p>
              </div>
            </el-footer>
        </el-container>


    </div>
</template>

<script>
    // logo
    import logo from '@/assets/imgs/logo.png'

    export default {
      name: 'app',
      data() {
        return {
          logo: logo,
          activeIndex: this.$store.state.seq,
          isShowFooter: this.$store.state.isShowFooter
        };
      },
      methods: {
        handleSelect(key) {
//          console.log(key, 'this.$store.state.seq,' + this.$store.state.seq, this.isShowFooter);
          switch (key) {
            case '1':
              this.$store.commit('changeFooterStatus', false)
              this.$router.push({name: 'home'});
              break;
            case '2':
              this.$store.commit('changeFooterStatus', true)
              this.$router.push({name: 'solution'});
              break;
            case '3':
              this.$store.commit('changeFooterStatus', true)
              this.$router.push({name: 'cicadas'});
              break;
            case '4':
              this.$store.commit('changeFooterStatus', true)
              this.$router.push({name: 'about'});
              break;
            case '5':
              this.$store.commit('changeFooterStatus', true)
              this.$router.push({name: 'contact'});
              break;
            case '6':
              this.$store.commit('changeFooterStatus', true)
              this.$router.push({name: 'design'});
              break;
            default:
              this.$store.commit('changeFooterStatus', false)
              this.$router.push({name: 'home'});
              break;
          }

        },
        getNavType() {
          this.activeIndex = this.$store.state.seq;
          //store.state.seq，设置activeIndex
        }
      },
      mounted() {
//          const script = document.createElement('script')
//          script.src = 'https://s95.cnzz.com/z_stat.php?id=1111111111&web_id=1111111111'
//          script.language = 'JavaScript'
//          document.body.appendChild(script)
          try {
              document.body.removeChild(document.getElementById('appLoading'))
              setTimeout(function() {
                  document.getElementById('app').style.display = 'block';
              }, 500)
          } catch (e) {

          }
      },
      watch: {
        "$store.state.isShowFooter": function () {
          this.isShowFooter = this.$store.state.isShowFooter;
        },
        '$store.state.seq': 'getNavType',
//        '$route'() {
//          console.log('window.location=====>' + window.location + 'location===>' + location)
//          if (window._czc) {
//            let location = window.location
//            let contentUrl = location.pathname + location.hash
//            let refererUrl = '/'
//            window._czc.push(['_trackPageview', contentUrl, refererUrl])
//          }
//        }
      }
    }

</script>

<style>
    body {
        margin: 0;
        min-width: 1024px;
    }
    a {
      text-decoration: none;
    }

    /*覆盖菜单栏样式，使它没有下边框*/
    .el-menu--horizontal {
        border-bottom: solid 0px red !important;
    }
    .el-main {
      background-color: #f8fbfd;
    }
    /*隐藏友盟图标*/
    #cnzz_stat_icon_1273162985 {
      display: none;
    }

    .padding-override{
        padding: 0!important;
    }

    .padding-override .logo {
        width: 130px;
        padding: 20px 50px 10px 0;
    }


    .el-menu-demo .item{
        margin-top:15px!important;
        height: 75px!important;
        font-size: 1rem;
        font-weight: bold;
    }

    /*底部*/
    .footerBox {
      height:230px;
      width:100%;
      overflow: hidden;
      background-color: rgba(3,0,1,0.9);
      padding-bottom:12px;
      font-family:Microsoft YaHei;
    }
    .footerBox .footerList {
      display: flex;
      justify-content: center;
    }
    .footerBox .footerList li{
      width:10%;
      text-align: left;
      margin-top:6px;
      display: flex;
      flex-direction: column;
    }
    .footerBox .footerList li:nth-child(1){
      width:12%;
      margin-right:10px;
    }
    .footerBox .footerList li:nth-child(5) {
      width:18%;
      margin-right:10px;
    }
    .footerBox .footerList li:nth-child(6){
      text-align: center;
      width:8%;
      margin-right:28px;
    }
    .footerBox .footerList li.logoItem{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .footerBox .footerList li .logo{
      font-size:40px;
      color: #fffefe;
      margin-bottom:10px;
    }
    .footerBox .footerList li .title{
      margin-bottom:10px;
      color: #dfdede;
      font-size:19px;
    }
    .footerBox .footerList li .subTitle {
      width:100%;
      margin-bottom:6px;
      margin-top:6px;
      color: #a09f9f;
      font-size:14px;
      text-align: left;
    }
    .footerBox .footerList li .logoImg {
      display: block;
      width:183px;
      height:127px;
    }
    .footerBox .footerList li .codeImg {
      display: block;
      width: 90px;
      height: 90px;
    }
    .footerBox .recordeText {
      margin-top: 14px;
      text-align: center;
      width: 100%;
      color: #fff;
      font-size: 13px;
    }
  .cursorPoint:hover {
    cursor: pointer;
  }
</style>

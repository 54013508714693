<template>
  <div class="home">
      <!-- Swiper -->
      <div class="swiper-container" v-bind:style="{height: (fullHeight - 90) + 'px'}">
          <div class="swiper-wrapper">
            <!--首屏-->
              <div class="swiper-slide">
                  <div style="width: 100%;">
                      <el-carousel :interval="4000" v-bind:height="(fullHeight - 90) + 'px'">
                          <el-carousel-item class="el-carousel-item" v-for="(item,index) in banners" :key="index"
                                            v-bind:style="{backgroundImage:'url(' + item.image + ')'}">
                              <div class="msgbox">
                                  <div class="title" v-bind:style="{backgroundImage:'url(' + bannerBox + ')'}">
                                      <div class="title-p1">
                                          {{item.title}}
                                      </div>
                                      <div class="title-p2">
                                        {{item.subTitle}}
                                      </div>
                                  </div>
                              </div>
                              <div class="msgbox">
                                  <div @click="goSolution" class="know-more cursorPoint" v-bind:style="{backgroundImage:'url(' + bannerKnowMore + ')'}">
                                      <div class="know-more-p1">
                                          了解更多
                                      </div>
                                  </div>
                              </div>
                          </el-carousel-item>
                      </el-carousel>
                  </div>
              </div>
            <!--第二屏-->
              <div class="swiper-slide swiper-slide-1">
                <section class="operationBox" >
                  <h2 class="title title_animate_init">您是否也在为软件服务产生的各种问题担忧？</h2>
                  <p class="sonTitle sonTitle_animate_init">即刻选择我们，pc手机一键创建实时编辑不再烦恼</p>
                  <div class="operImg operImg_animate_init">
                    <div class="dotBox dotPosition1 dotPosition1_animate_init">
                      <p class="dotTitle">1.安全性</p>
                      <p class="dotContent">自己不会开发软件，找人开发安全吗，会不会影响后期发展呢</p>
                    </div>
                    <div class="dotBox dotPosition2 dotPosition2_animate_init">
                      <p class="dotTitle">2.编辑性</p>
                      <p class="dotContent">又不懂代码，软件要是出现问题要怎么办</p>
                    </div>
                    <div class="dotBox dotPosition3 dotPosition3_animate_init">
                      <p class="dotTitle">3.稳定性</p>
                      <p class="dotContent">服务器的稳定性对软件会有什么影响吗</p>
                    </div>
                    <div class="dotBox dotPosition4 dotPosition4_animate_init">
                      <p class="dotTitle">4.及时性</p>
                      <p class="dotContent">软件服务如果出现问题是否能有专业的人员及时处理和解决</p>
                    </div>
                    <div class="dotBox dotPosition5 dotPosition5_animate_init">
                      <p class="dotTitle">5.海量性</p>
                      <p class="dotContent">用户多，并且数据量大，该如何保证系统稳健运行</p>
                    </div>
                    <button class="chatBtn cursorPoint" @click="goContent">立即咨询</button>
                  </div>
                </section>
              </div>
            <!--第三屏-->
            <div class="swiper-slide swiper-slide-2">
                <section class="aboutBox">
                  <h2 class="title title_animate_init">关于我们</h2>
                  <p class="sonTitle sonTitle_animate_init">广州都灵源链信息科技有限公司，专注于为企业提供整体软件解决方案与软件服务的技术服务公司，公司汇聚中国百强企业的资深营销专家、技术开发专家，拥有产品工程师、研发工程师、品牌策划师、创意设计师等众多专业人员，依托于强大的技术支撑及移动互联网营销能力优势，整合商业资源、媒介资源，提供软件定制服务，帮助客户实现价值最大化，为所有谋求长远发展的企业提升品牌品质，尽可能的为用户考虑及功能、操作、体验交互的专业软件。</p>
                  <img class="aboutImg aboutImg_animate_init" src="../assets/imgs/home/aboutUs.png" alt="aboutUsImg" />
                </section>
            </div>
            <!--第四屏-->
            <div class="swiper-slide swiper-slide-1">
                <section class="serviceBox">
                  <h2 class="title title_animate_init">建立一站式软件服务</h2>
                  <div class="serviceImg serviceImg_animate_init">
                    <p class="desText desposition1 desposition1_animate_init">网站开发</p>
                    <p class="desText desposition2 desposition2_animate_init">安卓 APPS</p>
                    <p class="desText desposition3 desposition3_animate_init">IOS APPS</p>
                    <p class="desText desposition4 desposition4_animate_init">小程序开发</p>
                    <p class="desText desposition5 desposition5_animate_init">微商城</p>
                  </div>

                  <ul class="textBox textPosition1 textPosition1_animate_init">
                    <li>在线教育系统</li>
                    <li>资源管理系统</li>
                    <li>在线拼团系统</li>
                    <li>万人分销系统</li>
                  </ul>
                  <ul class="textBox textPosition2 textPosition2_animate_init">
                    <li>企业门户网站</li>
                    <li>微商城小程序</li>
                    <li>项目管理系统</li>
                    <li>系统二次开发</li>
                  </ul>
                </section>
            </div>
            <!--第五屏-->
            <div class="swiper-slide swiper-slide-2">
                <section class="strongBox">
                  <h2 class="title title_animate_init">强大团队令您值得信赖</h2>
                  <div class="strongContent">
                    <div class="strongItem strongPosition1 strongPosition1_animate_init">
                      <img src="../assets/imgs/home/sure1.png" alt="icon1">
                      <p class="subTitle">功能更强大</p>
                      <p class="subText">支持多组织、多核算体系、多账薄、阿米巴、快速弹性扩容、快速功能迭代。</p>
                    </div>
                    <div class="strongItem strongPosition2 strongPosition2_animate_init">
                      <img src="../assets/imgs/home/sure2.png" alt="icon1">
                      <p class="subTitle">数据更安全</p>
                      <p class="subText">提供高级别的数据安全保障，包括ISO27001、SOC1、SOC2、SOC3、CSA。</p>
                    </div>
                    <div class="strongItem strongPosition3 strongPosition3_animate_init">
                      <img src="../assets/imgs/home/sure3.png" alt="icon1">
                      <p class="subTitle">价值更大化</p>
                      <p class="subText">为企业量身定做，提供整体软件解决方案与软件服务，帮助客户实现价值最大化。</p>
                    </div>
                    <div class="strongItem strongPosition4 strongPosition4_animate_init">
                      <img src="../assets/imgs/home/sure4.png" alt="icon1">
                      <p class="subTitle">售后更贴心</p>
                      <p class="subText">提供7*24小时技术支持，包括在线客服、在线提单、售后热线。</p>
                    </div>
                  </div>
                </section>
              </div>
        <!--第六屏-->
        <div class="swiper-slide swiper-slide-1">
            <section class="planBox">
              <h2 class="title title_animate_init">软件开发看我们提供的解决方案</h2>
              <p class="sonTitle sonTitle_animate_init">让建站更简单、更轻松、更高效</p>
              <div class="planContent planContent_animate_init">
                <div class="planItem planPosition1 planPosition1_animate_init">
                  <p class="subTitle"><p class="subWord">A</p>产品设计</p>
                  <p class="subText">信息架构、界面布局、快速原型、产品原型、低保真原型</p>
                </div>
                <div class="planItem planPosition2 planPosition2_animate_init">
                  <p class="subTitle"><p class="subWord">B</p>软件设计</p>
                  <p class="subText">前端架构、系统架构及开发、安全机制</p>
                </div>
                <div class="planItem planPosition3 planPosition3_animate_init">
                  <p class="subTitle"><p class="subWord">C</p>数据管理</p>
                  <p class="subText">海量数据的实时处理与存储分析、多维度全方位的画像信息</p>
                </div>
                <div class="planItem planPosition4 planPosition4_animate_init">
                  <p class="subTitle"><p class="subWord">D</p>运营&营销</p>
                  <p class="subText">新媒体运营与营销服务、洞察用户感知与转化</p>
                </div>
              </div>
            </section>
          </div>
        <!--第七屏-->
        <div class="swiper-slide swiper-slide-2">
            <section class="goodBox">
              <h2 class="title title_animate_init">选择我们必不可少的四大优势</h2>
              <p class="sonTitle sonTitle_animate_init">让软件定制更简单、更轻松、更高效</p>
              <div class="goodContent">
                <div class="contentLeft contentLeft_animate_init">
                  <img src="../assets/imgs/home/goodImg.png" alt="goodImg">
                </div>
                <div class="contentRight">
                  <div class="goodItem goodPosition1 goodPosition1_animate_init">
                    <p class="sign signPosition1">01</p>
                    <div class="signContent">
                      <p class="subTitle">高端软件定制</p>
                      <p class="subText">专业资深的软件设计团队针对有品牌意识的企业，提辅助企业构建互联网竞争力。</p>
                    </div>
                  </div>
                  <div class="goodItem goodPosition2 goodPosition2_animate_init">
                    <p class="sign signPosition2">02</p>
                    <div class="signContent">
                      <p class="subTitle">软件解决方案</p>
                      <p class="subText">我们拥有专业的技术团队与丰富的建设经验，专注于为企业提供整体软件解决方案与软件服务。</p>
                    </div>
                  </div>
                  <div class="goodItem goodPosition3 goodPosition3_animate_init">
                    <p class="sign signPosition3">03</p>
                    <div class="signContent">
                      <p class="subTitle">大数据平台</p>
                      <p class="subText">基于领先的大数据处理能力，为各行业提供强大的大数据产品及服务，一站式大数据分析平台及解决方案。</p>
                    </div>
                  </div>
                  <div class="goodItem goodPosition4 goodPosition4_animate_init">
                    <p class="sign signPosition4">04</p>
                    <div class="signContent">
                      <p class="subTitle">新媒体营销服务</p>
                      <p class="subText">实现精准化、可视化的广告投放，高覆盖以及可闭环，提供强大的精准营销能力。</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
      </div>

      <div class="swiper-pagination"></div>

      </div>
  </div>
</template>

<script>
    // banner
    import banner from '@/assets/imgs/home/banner.png';
    import banner1 from '@/assets/imgs/home/banner1.png';
    import banner2 from '@/assets/imgs/home/banner2.png';
    import bannerBox from '@/assets/imgs/home/banner-box.png';
    import bannerKnowMore from '@/assets/imgs/home/banner-know-more.png';
    // swiper
    import Swiper from  'swiper';
    import 'swiper/dist/css/swiper.min.css';

    export default {
        name: 'home',
        // SEO优化
        metaInfo () {
            return {
                title: this.pageName,
                meta: [{
                    name: 'title',
                    content: '都灵源链-互联网+技术合伙人【官网】'
                },{
                    name: 'keywords',
                    content: '合伙人，源链臻选，技术合伙，小程序外包，小程序商城，小程序代理'
                },{
                    name: 'description',
                    content: '都灵源链【www.torinosrc.com】是广州软件服务供应商，小微企业的技术合伙人，提供人力外包、项目外包、小程序商城等，认准【都灵源链】品牌外包开发商。'
                }],
                link: [{                 // set link
                    rel: 'asstes',
                    href: 'https://assets-cdn.github.com/'
                }]
            }
        },
        data() {
            return {
                pageName: this.$store.state.title,
                banners: [
                  {
                    "title":"助力上万企业成功的小能手",
                    "subTitle":"创意设计 软件定制",
                    "image":banner
                  },
                  {
                    "title":"专业的软件开发系统",
                    "subTitle":"贴近目标群体 驱动品牌价值",
                    "image":banner1
                  },
                  {
                    "title":"定制软件开发",
                    "subTitle":"我们用设计提升企业和产品价值",
                    "image":banner2
                  }
                ],
                bannerBox: bannerBox,
                bannerKnowMore: bannerKnowMore,
                fullHeight: document.documentElement.clientHeight + 90
            }
        },
        methods: {
          goContent() {
//            跳转联系我们页面
            this.$store.commit('changeFooterStatus',true)
            this.$router.push({name: 'contact'});
          },
          goSolution() {
//            跳转解决方案页面
            this.$store.commit('changeFooterStatus',true)
            this.$router.push({name: 'solution'});
          }
        },
        created(){
            const that = this
            console.log('fullHeight-created: ' + that.fullHeight);
            window.fullHeight = document.documentElement.clientHeight
            that.fullHeight = window.fullHeight
        },
        mounted() {
            const that = this

            // 窗口大小改变时，改变高度
            window.onresize = () => {
                return (() => {
                    window.fullHeight = document.documentElement.clientHeight
                    that.fullHeight = window.fullHeight
                })()
            };

            console.log('fullHeight-mounted: ' + that.fullHeight);

            // 设置竖方向轮播
            let swiper = new Swiper('.swiper-container', {
                direction : 'vertical',
                followFinger : false,
                speed:800,
                mousewheel: true,
                pagination : {
                    el:'.swiper-pagination',
                },
                on:{
                    init:function(swiper){
                        let slide=this.slides.eq(0);
                        slide.addClass('ani-slide');
                    },
                    transitionStart: function(){
                        for(let i=0;i<this.slides.length;i++){
                            let slide=this.slides.eq(i);
                            slide.removeClass('ani-slide');
                        }
                    },
                    transitionEnd: function(){
                        let slide=this.slides.eq(this.activeIndex);
                        slide.addClass('ani-slide');

                    },
                }
            });

            // 设置页面名称
            setTimeout(() => {
                this.pageName = this.$store.state.title;
            }, 2000)
        },
        watch: {
            fullHeight(val) {
                console.log('fullHeight-watch: ' + val);
                if (!this.timer) {
                    this.fullHeight = val
                    this.timer = true
                    let that = this
                    setTimeout(function () {
                        that.timer = false
                    }, 400)
                }
            }
        }
    }
</script>

<style type="less">
    /* 全屏竖方向切换   */
    .swiper-container {
        width: 100%;
    }

    .swiper-wrapper{
        transition-delay:.3s;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
    .swiper-slide:nth-child(2){
        background:#f3f9fe;
    }
    /*.swiper-slide:nth-child(3){*/
      /*background:#f3f9fe;*/
    /*}*/
    .swiper-slide:nth-child(4){
      background:#f8fbfd;
    }
    .swiper-slide:nth-child(5){
      background:#f3f9fe;
    }
    .swiper-slide:nth-child(6){
      background:#f8fbfd;
    }
    .swiper-slide:nth-child(7){
      background:#f3f9fe;
    }
    .swiper-slide-1 p{
      transform:translateX(-200px);
      opacity:0;
      transition:all .4s;
    }
    .swiper-slide-2 p{
      transform:translateX(200px);
      opacity:0;
      transition:all .4s;
    }
    .ani-slide p{
        transform:translateX(0);
        opacity:1;
    }
    .cursorPoint:hover {
      cursor: pointer;
    }



    /*第一版界面样式*/
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }

    .el-carousel-item {
        backgroundRepeat: 'no-repeat';
        backgroundPosition: 'center center';
        backgroundSize: 'cover';
    }

    .msgbox {
        display: flex;
        justify-content: center;
    }

    .msgbox .title {
        margin: 120px 0 0 0;
        width: 672px;
        height: 229px;
        backgroundRepeat: 'no-repeat';
        backgroundPosition: 'center center';
        backgroundSize: 'contain';
    }

    .title-p1 {
        color: white;
        font-size: 52px;
        display: flex;
        justify-content: center;
        margin: 40px 0 0 0;
    }

    .title-p2 {
        color: white;
        font-size: 38px;
        display: flex;
        justify-content: center;
        margin: 40px 0 0 0;
    }

    .know-more {
        margin: 162px 0 0 0;
        width: 162px;
        height: 58px;
        backgroundRepeat: 'no-repeat';
        backgroundPosition: 'center center';
        backgroundSize: 'contain';
    }

    .know-more-p1 {
        color: white;
        font-size: 28px;
        display: flex;
        justify-content: center;
        margin: 10px 0 0 0;
    }

    /*第二版界面样式*/
    .operationBox {
      width:100%;
      height:100%;
      position: relative;
      overflow: hidden;
      text-align: center;
    }

    .operationBox .title {
      margin-top:50px;
      margin-bottom:10px;
      font-size:30px;
      font-weight:700;
      color: #262525;
    }

    .operationBox .title text {
      color: #2859a8;
    }

    .operationBox .sonTitle {
      margin-top:10px;
      font-size:18px;
      color: #343434;
    }

    .operationBox .operImg {
      background: url('../assets/imgs/home/circle.png') no-repeat center center;
      width:610px;
      height:540px;
      position: absolute;
      top:20%;
      left:50%;
      transform: translateX(-50%);
    }

    .operationBox .dotBox {
      position: absolute;
      width:160px;
      text-align:left;
    }

    .operationBox .dotBox .dotTitle {
      font-size:18px;
      font-weight:700;
      color: #373737;
      margin-bottom:6px;
    }

    .operationBox .dotBox .dotContent {
      font-size:14px;
      color: #5c5c5c;
    }

    /*设置小圆点闪烁*/
    .operationBox .dotBox:before,
    .operationBox .dotBox:after {
      content: ' ';
      display: block;
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
    }

    .operationBox .dotBox:before {
      -webkit-animation: bounce 2s infinite ease-in-out 1s;
      animation: bounce 2s infinite ease-in-out 1s;
    }
    .operationBox .dotBox:after {
      -webkit-animation: bounce 2s infinite ease-in-out 0s;
      animation: bounce 2s infinite ease-in-out 0s;
    }

    @-webkit-keyframes bounce {
      0%, 100% {-webkit-transform: scale(0)}
      50% {-webkit-transform:scale(1);}
    }

    .operationBox .dotPosition1 {
      top:28px;
      left:524px;
    }
    .operationBox .dotPosition1:before,
    .operationBox .dotPosition1:after {
      top:20px;
      left:-68px;
      background-color: #fe8500;
    }
    .operationBox .dotPosition2 {
      top:310px;
      left:590px;
    }
    .operationBox .dotPosition2:before,
    .operationBox .dotPosition2:after {
      top:16px;
      left:-44px;
      background-color: #fe2d00;
    }
    .operationBox .dotPosition3 {
      top:46px;
      left:-82px;
      text-align: right;
    }
    .operationBox .dotPosition3:before,
    .operationBox .dotPosition3:after {
      top:35px;
      right:-48px;
      background-color: #ffde00;
    }
    .operationBox .dotPosition4 {
      top:245px;
      left:-148px;
      text-align: right;
    }
    .operationBox .dotPosition4:before,
    .operationBox .dotPosition4:after {
      top:21px;
      right:-51px;
      background-color: #46dee6;
    }
    .operationBox .dotPosition5 {
      top:446px;
      left:-60px;
      text-align: right;
    }
    .operationBox .dotPosition5:before,
    .operationBox .dotPosition5:after {
      top:-8px;
      right:-38px;
      background-color: #2dcace;
    }

    .operationBox .chatBtn {
      position: absolute;
      display: block;
      top:495px;
      left:243px;
      width:100px;
      height:45px;
      line-height:45px;
      text-align: center;
      background-color: #02b3b4;
      color: #ffffff;
      border:none;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
    }
    /*第三版界面样式*/
    .aboutBox {
      display: block;
      height:100%;
      width:100%;
      text-align: center;
      overflow: hidden;
      background-color:#f8fbfd;
    }

    .aboutBox .title {
      margin-top:50px;
      margin-bottom:10px;
      font-size:30px;
      font-weight:700;
    }

    .aboutBox .sonTitle {
      margin: 15px auto 50px;
      font-size:16px;
      line-height:26px;
      width:70%;
      color: #3c3c3c;
      text-align: left;
    }

    .aboutBox .aboutImg {
      margin:0 auto;
      display: block;
      overflow: hidden;
    }
  /*第四版界面样式*/
    .strongBox {
      background:url('../assets/imgs/home/background.png') no-repeat left bottom;
      display: block;
      width:100%;
      height: 100%;
      text-align: center;
    }

    .strongBox .title {
      margin-top:50px;
      font-size:30px;
    }

    .strongBox .title text {
      color:#2859a8;
    }
    .strongContent {
      margin-top:130px;
      display: flex;
      justify-content: center;
      width:100%;
    }

    .strongContent .strongItem {
      width:20%;
      text-align: center;
      padding:0 20px;
    }

    .strongContent .strongItem .subTitle{
      margin-top:30px;
      font-size:16px;
      color:#4a4a4a;
    }

    .strongContent .strongItem .subText {
      width:82%;
      margin: 10px auto;
      text-align: left;
      font-size:15px;
      color: #9f9fa0;
    }
    .serviceBox {
      display: block;
      height:100%;
      width: 100%;
      text-align: center;
      position: relative;
      overflow: hidden;
    }

    .serviceBox .title {
      margin-top:50px;
      margin-bottom:10px;
      font-size:30px;
    }

    .serviceBox .title text {
      color:#2859a8;
    }

    .serviceBox .serviceImg {
      background: url('../assets/imgs/home/service.png') no-repeat;
      width:400px;
      height:340px;
      position: absolute;
      top:140px;
      left:50%;
      transform: translateX(-50%);
    }

    .serviceBox .desText {
      position: absolute;
      color: #4d4e4f;
      width:100px;
    }

    .serviceBox .desposition1 {
      top:62px;
      left:-92px;
    }

    .serviceBox .desposition2 {
      top:222px;
      left:-80px;
    }

    .serviceBox .desposition3 {
      top:30px;
      right:-80px;
    }

    .serviceBox .desposition4 {
      top:185px;
      right:-100px;
    }

    .serviceBox .desposition5 {
      top:282px;
      right:25px;
    }

    .serviceBox .textBox {
      width:100%;
      position: relative;
      top:470px;
      padding-left: 130px;
      box-sizing: border-box;
    }

    .serviceBox .textBox li {
      width:100px;
      height:30px;
      line-height:30px;
      text-align: left;
      display: inline-block;
      margin-right:130px;
      font-size:16px;
      color: #5a4f5a;
    }
    /*第五版样式*/

    .planBox {
      display: block;
      height:100%;
      width:100%;
      text-align: center;
    }

    .planBox .title {
      font-size:30px;
      margin-top:50px;
      font-weight:700;
    }
    .planBox .title text {
      color:#2859a8;
    }

    .planBox .sonTitle {
      font-size:16px;
      color:#555555;
      height:40px;
      line-height: 40px;
    }

    .planBox .planContent {
      background: url('../assets/imgs/home/plan.png');
      width:570px;
      height:460px;
      margin:20px auto;
      position: relative;
    }
    .planContent .planItem {
      position: absolute;
      width:220px;
      text-align: left;
    }

    .planContent .planPosition1 {
      left:-220px;
      top:15px;
      text-align: right;
    }
    .planContent .planPosition2 {
      right:-230px;
      top:15px;
    }
    .planContent .planPosition3 {
      text-align: right;
      left:-220px;
      top:285px;
    }
    .planContent .planPosition4 {
      right:-230px;
      top:285px;
    }

    .planContent .subTitle {
      color:#5d6062;
      font-size:18px;
    }

    .planContent .subWord {
      margin: 0 10px 0 0;
      color: #c1d7fc;
      font-size:50px;
      font-weight:700;
    }

    .planContent .subText {
      font-size:15px;
      color: #a2a5a5;
    }
    /*第六版样式*/
    .goodBox {
      display: block;
      height:100%;
      width:100%;
      overflow: hidden;
      text-align: center;
    }

    .goodBox .title {
      margin-top:50px;
      font-size:30px;
      font-weight:700;
    }

    .goodBox .title text{
      color:#2859a8;
    }

    .goodBox .sonTitle {
      margin: 10px auto 10px;
      font-size:18px;
      width:50%;
    }

    .goodBox .goodContent {
      display: flex;
      justify-content: center;
    }

    .goodBox .contentRight {
      margin-top:0;
    }

    .goodContent .goodItem {
      display: flex;
      justify-content: center;
      margin-top:20px;
    }

    .goodContent .signContent {
      width:60%;
      text-align: left;
      padding-right:10px;
    }

    .goodItem .sign {
      font-size:66px;
      font-style: italic;
    }

    .goodItem .signPosition1 {
      margin:0 30px 0 0;
      color: #fea35d;
    }

    .goodItem .signPosition2 {
      margin:0 45px 0 0;
      color: #45d2fd;
    }

    .goodItem .signPosition3 {
      margin:0 55px 0 0;
      color:#2dd1d6;
    }

    .goodItem .signPosition4 {
      margin:0 65px 0 0;
      color: #7fb0eb;
    }

    .goodItem .subTitle {
      margin-top:10px;
      color: #6c6f6f;
    }

    .goodItem .subText {
      margin-top:10px;
      color:#9ea1a1;
      font-size:14px;
    }
</style>

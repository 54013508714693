<template>
  <div>
    <div class="bgColor">
      <el-carousel :interval="4000" class="carousel">
        <el-carousel-item v-for="item in designBanners"  :key="item" v-bind:style="{background:'url(' + item + ') no-repeat',backgroundSize:'100% 100%'}">
        </el-carousel-item>
      </el-carousel>
        <div class="tabTitle">
          <div style="color:#409eff;font-size:15x;">精品推荐</div>
          <el-button type="primary" plain @click="showMore">更多</el-button>
        </div>
    </div>

      <div class="designRow">
        <div class="designItem" @click="dialogVisible = true">
          <img src="../assets/imgs/home/designavator.jpg" style="width: 80px;height: 75px;"/>
          <p style="margin:10px 0;">唯意</p>
          <div class="designBox">
            <p style="margin-bottom:10px;margin-top: 0;font-size:12px">资深设计师</p>
            <!--<p style="margin-bottom:10px;margin-top: 0;font-size:12px">微信: ydk13232706570</p>-->
          </div>
          <div class="designBox">
            <!--<img src="../assets/imgs/home/placeIcon.png" style="width: 16px;height: 16px;"/>-->
            <p style="margin-bottom:10px;margin-top: 0;font-size: 12px;">现居: 广东省 广州市</p>
          </div>
          <div class="textRow">
            <img src="../assets/imgs/home/workIcon.png" style="width: 20px;height: 20px;"/>
            <p style="margin:0 10px;">设计师作品</p>
          </div>
        </div>
        <div class="designItem">
          <img src="../assets/imgs/home/design/design1.jpg" style="width: 195px;height:155px"/>
          <div class="placeItem">
            <p style="margin:0 0;">婚庆官网设计</p>
          </div>
          <div class="textRow">
            <img src="../assets/imgs/home/designavator.jpg" style="width: 20px;height: 20px;"/>
            <p style="margin:0 10px;">唯意</p>
          </div>
        </div>
        <div class="designItem">
          <img src="../assets/imgs/home/design/design2.png" style="width: 195px;height:155px"/>
          <div class="placeItem">
            <p style="margin:0 0;">鲜花官网设计</p>
          </div>
          <div class="textRow">
            <img src="../assets/imgs/home/designavator.jpg" style="width: 20px;height: 20px;"/>
            <p style="margin:0 10px;">唯意</p>
          </div>
        </div>
        <div class="designItem designlastItem">
          <img src="../assets/imgs/home/design/design3.png" style="width: 195px;height:155px"/>
          <div class="placeItem">
            <p style="margin:0 0;">服装官网设计</p>
          </div>
          <div class="textRow">
            <img src="../assets/imgs/home/designavator.jpg" style="width: 20px;height: 20px;"/>
            <p style="margin:0 10px;">唯意</p>
          </div>
        </div>
      </div>
      <div class="designRow">
        <div class="designItem" v-for="item in design1DataOne" :key="">
          <img :src="item.imgUrl" style="width: 195px;height:155px"/>
          <div class="placeItem">
            <p style="margin:0 0;">{{item.title}}</p>
          </div>
          <div class="textRow">
            <img src="../assets/imgs/home/designavator.jpg" style="width: 20px;height: 20px;"/>
            <p style="margin:0 10px;">{{item.name}}</p>
          </div>
        </div>
      </div>
      <!--<div class="designRow">-->
        <!--<div class="designItem" v-for="item in design1DataTwo" :key="">-->
          <!--<img :src="item.imgUrl" style="width: 195px;height:155px"/>-->
          <!--<div class="placeItem">-->
            <!--<p style="margin:0 0;">{{item.title}}</p>-->
          <!--</div>-->
          <!--<div class="textRow">-->
            <!--<img src="../assets/imgs/home/designavator.jpg" style="width: 20px;height: 20px;"/>-->
            <!--<p style="margin:0 10px;">{{item.name}}</p>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
      <!--TODO:暂时没有-->
      <!--第二个设计师-->
      <!--<div class="designRow">-->
        <!--<div class="designItem">-->
          <!--<img src="../assets/imgs/home/designavator1.jpg" style="width: 100px;height: 75px;"/>-->
          <!--<p style="margin:10px 0;">唯意</p>-->
          <!--<p style="margin:10px 0;">微信: ydk13232706570</p>-->
          <!--<div class="placeItem">-->
            <!--<img src="../assets/imgs/home/placeIcon.png" style="width: 20px;height: 20px;"/>-->
            <!--<p style="margin:0 0;">广东省 广州市</p>-->
          <!--</div>-->
          <!--<div class="textRow">-->
            <!--<img src="../assets/imgs/home/placeIcon.png" style="width: 20px;height: 20px;"/>-->
            <!--<p style="margin:0 10px;">设计师专访</p>-->
          <!--</div>-->
        <!--</div>-->
        <!--<div class="designItem">-->
          <!--<img src="../assets/imgs/home/designcomputer.jpg" style="width: 195px;height:155px"/>-->
          <!--<div class="placeItem">-->
            <!--<p style="margin:0 0;">婚庆官网设计</p>-->
          <!--</div>-->
          <!--<div class="textRow">-->
            <!--<img src="../assets/imgs/home/designavator1.jpg" style="width: 20px;height: 20px;"/>-->
            <!--<p style="margin:0 10px;">唯意</p>-->
          <!--</div>-->
        <!--</div>-->
        <!--<div class="designItem">-->
          <!--<img src="../assets/imgs/home/designcomputer.jpg" style="width: 195px;height:155px"/>-->
          <!--<div class="placeItem">-->
            <!--<p style="margin:0 0;">婚庆官网设计</p>-->
          <!--</div>-->
          <!--<div class="textRow">-->
            <!--<img src="../assets/imgs/home/designavator1.jpg" style="width: 20px;height: 20px;"/>-->
            <!--<p style="margin:0 10px;">唯意</p>-->
          <!--</div>-->
        <!--</div>-->
        <!--<div class="designItem designlastItem">-->
          <!--<img src="../assets/imgs/home/designcomputer.jpg" style="width: 195px;height:155px"/>-->
          <!--<div class="placeItem">-->
            <!--<p style="margin:0 0;">婚庆官网设计</p>-->
          <!--</div>-->
          <!--<div class="textRow">-->
            <!--<img src="../assets/imgs/home/designavator1.jpg" style="width: 20px;height: 20px;"/>-->
            <!--<p style="margin:0 10px;">唯意</p>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="designRow">-->
        <!--<div class="designItem" v-for="item in design1Data" :key="">-->
          <!--<img src="../assets/imgs/home/designcomputer.jpg" style="width: 195px;height:155px"/>-->
          <!--<div class="placeItem">-->
            <!--<p style="margin:0 0;">{{item.title}}</p>-->
          <!--</div>-->
          <!--<div class="textRow">-->
            <!--<img src="../assets/imgs/home/designavator1.jpg" style="width: 20px;height: 20px;"/>-->
            <!--<p style="margin:0 10px;">{{item.name}}</p>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->

    <el-dialog
      title="设计师信息"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div class="dialogBox">
        <img src="../assets/imgs/home/designavator.jpg" style="width: 100px;height: 75px;"/>
        <p>唯意</p>
        <div class="textRow">
          <p class="leftSign">昵称:</p>
          <p class="rightText">唯意</p>
        </div>
        <div class="textRow">
          <p class="leftSign">性别:</p>
          <p class="rightText">女</p>
        </div>
        <!--<div class="textRow">-->
          <!--<p class="leftSign">微信号:</p>-->
          <!--<p class="rightText">ydk13232706570</p>-->
        <!--</div>-->
        <div class="textRow">
          <p class="leftSign">现居:</p>
          <p class="rightText">广东省 广州市</p>
        </div>
      </div>
    </el-dialog>
  </div>

</template>
<script>
  import designBanner from '@/assets/imgs/home/designBanner.jpg';
  import designBanner1 from '@/assets/imgs/home/designBanner1.jpg';
  import designcomputer from '@/assets/imgs/home/designcomputer.jpg'
  import designavator1 from '@/assets/imgs/home/designavator1.jpg'
  import designImg1 from '@/assets/imgs/home/design/design4.png'
  import designImg2 from '@/assets/imgs/home/design/design5.png'
  import designImg3 from '@/assets/imgs/home/design/design6.png'
  import designImg4 from '@/assets/imgs/home/design/design7.png'
  import designImg5 from '@/assets/imgs/home/design/design8.png'
  import designImg6 from '@/assets/imgs/home/design/design9.png'
  import designImg7 from '@/assets/imgs/home/design/design10.png'

  export default {
      name: "design",
      // SEO优化
      metaInfo () {
          return {
              title: this.pageName,
              meta: [{
                  name: 'title',
                  content: '都灵源链-设计作品-互联网+技术合伙人【官网】'
              },{
                  name: 'keywords',
                  content: '都灵源链，设计作品，小程序商城'
              },{
                  name: 'description',
                  content: '都灵源链【www.torinosrc.com】致力于为企业提供整体软件解决方案与软件服务,专注软件设计、品牌定位、用心服务，帮您实现互联网品牌价值最大化。'
              }],
              link: [{                 // set link
                  rel: 'asstes',
                  href: 'https://assets-cdn.github.com/'
              }]
          }
      },
    data () {
      return {
          pageName: this.$store.state.title,
        isShowmore:false,
        designBanners:[
          designBanner,
          designBanner1
        ],
        design1DataOne:[
          {
            "designcomputer":designcomputer,
            "title":"教育官网设计",
            "name":"唯意",
            "imgUrl":designImg1
          },
          {
            "designcomputer":designcomputer,
            "title":"酒店官网设计",
            "name":"唯意",
            "imgUrl":designImg2
          },
          {
            "designcomputer":designcomputer,
            "title":"红酒官网设计",
            "name":"唯意",
            "imgUrl":designImg3
          },
          {
            "designcomputer":designcomputer,
            "title":"家具官网设计",
            "name":"唯意",
            "imgUrl":designImg4
          }
        ],
        design1DataTwo:[
          {
            "designcomputer":designcomputer,
            "title":"健身官网设计",
            "name":"唯意",
            "imgUrl":designImg5
          },
          {
            "designcomputer":designcomputer,
            "title":"茶叶官网设计",
            "name":"唯意",
            "imgUrl":designImg6
          },
          {
            "designcomputer":designcomputer,
            "title":"医疗机械官网设计",
            "name":"唯意",
            "imgUrl":designImg7
          },
//          {
//            "designcomputer":designcomputer,
//            "title":"家具官网设计",
//            "name":"唯意",
//            "imgUrl":designImg4
//          }
        ],
        design2Data:[
          {
            "designcomputer":designcomputer,
            "title":"婚庆官网设计",
            "name":"唯意"
          },
          {
            "designcomputer":designcomputer,
            "title":"婚庆官网设计",
            "name":"唯意"
          },
          {
            "designcomputer":designcomputer,
            "title":"婚庆官网设计",
            "name":"唯意"
          },
          {
            "designcomputer":designcomputer,
            "title":"婚庆官网设计",
            "name":"唯意"
          }
        ],
        design1MoreData:[
          {
            "designcomputer":designcomputer,
            "title":"婚庆官网设计",
            "name":"唯意"
          },
          {
            "designcomputer":designcomputer,
            "title":"婚庆官网设计",
            "name":"唯意"
          },
          {
            "designcomputer":designcomputer,
            "title":"婚庆官网设计",
            "name":"唯意"
          },
          {
            "designcomputer":designcomputer,
            "title":"婚庆官网设计",
            "name":"唯意"
          }
        ],
        activeItem:false,
        dialogVisible: false
      }
    },
    methods: {
      showMore() {
        this.$router.push({"name":"designMore"})
      },
      handleClose(done) {
        done();
      }
    },
    mounted() {

    }
  }
</script>

<style type="less" scoped>
  /*修改下拉框默认样式*/
.el-dropdown {
  font-size:15px;
}
.bgColor {
  background-color: #fff;
}
.textColorBlack {
  color: #606266;
}
.textColorBlue {
  color: #409EFF;
}
.carousel {
  width:60%;
  margin:20px auto;
  height:auto;
}
.tabTitle {
  width:60%;
  height:55px;
  margin:20px auto;
  padding:0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tabTitleElse {
  width:60%;
  height:55px;
  margin:20px auto;
  padding:0 20px;
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  align-items: center;
  color: #606266;
}
.subTab {
  display: flex;

}
.designRow {
  display: flex;
  justify-content: space-around;
  width:60%;
  margin:0 auto 20px;
  font-size:14px;
}

.designItem {
  width:23%;
  /*margin-right:3%;*/
  padding-top:20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.designlastItem {
  margin-right:0;
}
.placeItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height:30px;
}
.designBox {
  width:80%;
  text-align: left;
}

/*设计师弹窗*/
.textRow {
  width:100%;
  height:40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top:1px solid #f6f6f6;
  padding-left:20px;
}
.leftSign {
  width:60px;
}
.rightText {
  flex:1;
  color: #303133;
}

.moreDesignRow {
  display: flex;
  /*justify-content: space-around;*/
  width:60%;
  margin:0 auto 20px;
  font-size:15px;
}
.dialogBox {
  width:60%;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>

<template>
    <div class="contact">
      <div class="marketBox">
        <h2 class="title">知了集市</h2>
        <p class="text">知了集市是一个小程序平台，里面有丰富多样的小程序，帮您快速找到需要的小程序，专注于创意设计实现商业价值更大化，为有所谋求长远发展的企业提升品牌品质，尽可能的为用户考虑及功能、操作、体验交互一体化的专业软件。专注软件设计、品牌定位、用心服务，帮您实现互联网品牌价值最大化。</p>
        <div class="imgContent">
          <img src="../assets/imgs/home/market1.png" alt="market1">
          <img src="../assets/imgs/home/market2.png" alt="market2">
          <img src="../assets/imgs/home/market3.png" alt="market3">
        </div>
        <div class="ourService">
          <div class="serviceLeft">
            <h2 class="subTitle">我们的服务<span>OUR SERVICE</span></h2>
            <div class="serviceList">
              <div class="serviceItem">
                <div class="serviceTitle">
                  <img src="../assets/imgs/home/cloud.png" alt="cloud">
                  <p class="subText">云服务器超稳定</p>
                </div>
                <p class="desText">多地分布式多主机集群，不停机更新，确保不丢单</p>
              </div>
              <div class="serviceItem">
                <div class="serviceTitle">
                  <img src="../assets/imgs/home/phone.png" alt="phone">
                  <p class="subText">整体软件解决方案</p>
                </div>
                <p class="desText">致力于为企业提供整体软件解决方案与软件服务,专注软件设计、品牌定位、用心服务，帮您实现互联网品牌价值最大化</p>
              </div>
            </div>
            <div class="serviceList">
              <div class="serviceItem">
                <div class="serviceTitle">
                  <img src="../assets/imgs/home/global.png" alt="global">
                  <p class="subText">全球覆盖</p>
                </div>
                <p class="desText">您可以把文件放置在对业务有利的区域让用户实现快速高性能访问</p>
              </div>
              <div class="serviceItem">
                <div class="serviceTitle">
                  <img src="../assets/imgs/home/cost.png" alt="cost">
                  <p class="subText">成本效益高</p>
                </div>
                <p class="desText">我们有竞争力的定价匹配策略意味着你能借助云储存获得更大收益</p>
              </div>
            </div>
          </div>
          <img src="../assets/imgs/home/computer.png" class="imgItem" alt="computer">

        </div>
      </div>
    </div>
</template>
<script>
    export default {
        name: "cicadas",
        // SEO优化
        metaInfo () {
            return {
                title: this.pageName,
                meta: [{
                    name: 'title',
                    content: '都灵源链-知了集市-互联网+技术合伙人【官网】'
                },{
                    name: 'keywords',
                    content: '都灵源链，知了集市，小程序商城'
                },{
                    name: 'description',
                    content: '都灵源链【www.torinosrc.com】知了集市是一个小程序平台，里面有丰富多样的小程序，帮您快速找到需要的小程序。'
                }],
                link: [{                 // set link
                    rel: 'asstes',
                    href: 'https://assets-cdn.github.com/'
                }]
            }
        },
        data() {
            return {
                pageName: this.$store.state.title,
            }
        },
    }
</script>
<style scoped>
  .marketBox {
    width:100%;
    text-align: center;
  }
  .marketBox .title {
    color: #000000;
    font-size:28px;
    margin-top:45px;
    position: relative;
  }
  .marketBox .title:after {
    display: block;
    content: '';
    position: absolute;
    bottom: -6px;
    left:50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    width:60px;
    height:1px;
    background-color: #464747;
  }
  .marketBox .text {
    margin:30px auto 30px;
    width:65%;
    text-align: left;
    font-size: 15px;
    color:#3c3c3c;
    line-height:32px;
  }
  .marketBox .imgContent {
    display: flex;
    justify-content: center;
    margin-bottom:80px;
  }
  .marketBox .imgContent img{
    margin-right:52px;
    width:272px;
    height:484px;
    border:1px solid #000000;
  }
  .marketBox .ourService {
    width:65%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin:20px auto;
  }

  .marketBox .serviceList {
    margin-top:10px;
    text-align: left;
    display: flex;
  }
  .marketBox .subTitle {
    margin:0 auto 10px;
    text-align: left;
    font-size: 30px;
    color:#000102;
  }
  .marketBox .subTitle span{
    margin-left:10px;
    color: #c8c9c9;
    font-size:18px;
  }
  .marketBox .serviceLeft {
    width:62%;
  }
  .serviceList .serviceTitle {
    display: flex;
    align-items: flex-end;
  }
  .serviceList .serviceTitle img {
    width:65px;
    height:65px;
  }
  .serviceList .serviceItem {
    margin-top: 20px;
    margin-right:30px;
    width:50%;
  }
  .ourService .imgItem {
    display: block;
    width:34%;
    height:380px;
  }
  .serviceItem .subText {
    color: #1c1c1c;
    font-size:18px;
    margin: 0 0 10px 10px;
  }
  .serviceItem .desText {
    margin-top:10px;
    color: #a8a9a9;
    font-size:14px;
    line-height:20px;
  }
</style>
<template>
    <div class="contact">
      <div class="contactBox">
        <h2 class="title">联系我们<span>CONTACT US</span></h2>
        <div class="contactList">
          <div class="contactItem">
            <img src="../assets/imgs/home/icon1.png" alt="area">
            <p class="areaDes">广东省广州市天河区棠东东路5号</p>
            <p class="areaDes">远洋创意园B127</p>
          </div>
          <div class="contactItem borderBoth">
            <img src="../assets/imgs/home/icon2.png" alt="person">
            <p class="personDes">吕先生: 18027188617</p>
            <!--<p class="personDes">李先生: 13430312954</p>-->
            <!--<p class="personDes">谢小姐: 15017583069</p>-->
          </div>
          <div class="contactItem" >
            <img src="../assets/imgs/home/icon3.png" alt="email">
            <a href="mailto:lvxin@torinosrc.com?subject=合作沟通&body=您好："><p class="emailDes">吕先生:lvxin@torinosrc.com</p></a>
            <a href="mailto:lizhiming@torinosrc.com?subject=合作沟通&body=您好："><p class="emailDes">李先生:lizhiming@torinosrc.com</p></a>
            <a href="mailto:xiedongmei@torinosrc.com?subject=合作沟通&body=您好："><p class="emailDes">谢小姐:xiedongmei@torinosrc.com</p></a>
          </div>
        </div>
        <!--地图容器-->
        <baidu-map class="map" :center="{lng: 113.395974, lat: 23.132579}" :zoom="16">
          <bm-marker :position="{lng: 113.395974, lat: 23.132579}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
            <bm-label content="广州都灵源链信息科技有限公司" :labelStyle="{color: '#4c4c4c', border:'none',padding:'10px 20px',fontSize : '18px'}" :offset="{width: -150, height: -80}"/>
          </bm-marker>

        </baidu-map>
        <!--<img class="mapImg" src="../assets/imgs/home/map.png" alt="map"/>-->
      </div>
    </div>
</template>
<script>
    export default {
        name: "contact",
        // SEO优化
        metaInfo () {
            return {
                title: this.pageName,
                meta: [{
                    name: 'title',
                    content: '都灵源链-联系我们-互联网+技术合伙人【官网】'
                },{
                    name: 'keywords',
                    content: '都灵源链，联系我们，小程序商城'
                },{
                    name: 'description',
                    content: '都灵源链【www.torinosrc.com】致力于为企业提供整体软件解决方案与软件服务,专注软件设计、品牌定位、用心服务，帮您实现互联网品牌价值最大化。'
                }],
                link: [{                 // set link
                    rel: 'asstes',
                    href: 'https://assets-cdn.github.com/'
                }]
            }
        },
        data() {
            return {
                pageName: this.$store.state.title,
            }
        },
    }
</script>

<style type="less" scoped>
  .contactBox {
    width:80%;
    margin:0 auto;
    text-align: center;
  }
  .contactBox .title {
    margin-top:45px;
    font-size: 28px;
    color:#000102;
    position: relative;
  }
  .contactBox .title span{
    margin-left:10px;
    color: #c8c9c9;
    font-size:18px;
  }
  .contactBox .title:after {
    display: block;
    content: '';
    position: absolute;
    bottom: -40px;
    left:50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    width:70%;
    height:1px;
    background-color: #d7d7d7;
  }
  .contactBox .contactList {
    margin-top:100px;
    display: flex;
    justify-content: center;
  }
  .contactBox .contactList .contactItem{
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    width:26%;
  }
  .contactBox .contactList .contactItem p {
    text-align: center;
    font-size:15px;
    line-height: 28px;
    color:#3c3c3c;
    margin:4px auto;
  }

  .contactBox .contactList .contactItem img {
    /*width:56px;*/
    height:58px;
  }
    .borderBoth {
    margin-right:2%;
    margin-left:2%;
    border-left:1px solid #efefef;
    border-right:1px solid #efefef;
  }
  /*.contactBox .contactList .contactItem p.emailDes {*/
    /*text-align: left;*/
  /*}*/
  .contactBox .map {
    margin: 40px auto;
    width:80%;
    height:430px;
  }


</style>

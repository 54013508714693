import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import MetaInfo from "vue-meta-info";
import BaiduMap from "vue-baidu-map";

Vue.use(MetaInfo);
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "YDRc7YkWHa3TFrjoXUkfLGTcxcLKHAHx"
});

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
    store.state.title = to.meta.title;
    if (to.meta.isShowFooter === false) {
      store.state.isShowFooter = false;
    } else {
      store.state.isShowFooter = true;
    }
    store.state.seq = to.meta.seq;
  }
  next();
});

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

<template>
  <div class="about">
    <div class="aboutUsBox">
      <h2 class="title">关于我们<span>ABOUT US</span></h2>
      <p class="subTitle">广州都灵源链信息科技有限公司致力于为企业提供整体软件解决方案与软件服务，专注软件定制、网站设计、品牌设计、互联网营销且用心服务。依托于强大的技术支撑及移动互联网营销能力优势，整合商业资源、媒介资源，提供软件定制服务，帮助客户实现价值最大化，为所有谋求长远发展的企业提升品牌品质。拥有经验丰富的技术团队，专业的资深设计师，凝聚了一批行业内优秀的年轻人，富有创造力和奉献精神。 我们所做的一切都是以客户的利益为出发点，帮助企业建立全面且切实的市场形象，我们坚信与您共同成长！</p>
      <div class="imgUsBox">
        <img src="../assets/imgs/home/us1.png" alt="usImg">
        <img src="../assets/imgs/home/us2.png" alt="usImg">
        <img src="../assets/imgs/home/us3.png" alt="usImg">
      </div>
      <h2 class="title">我们的团队<span>OUR TEAM</span></h2>
      <p class="subTitle">我们是一支追求品质与力求不断超越自己的团队，每一位成员也是亲密的伙伴，与公司一起成长与发展。我们尊重每次合作的机会与挑战，不断精进，力求完美。团队秉承专注、专业的设计服务理念，让客户通过设计发挥产品的更大价值，并发掘无限的可能。热爱设计并坚信设计的力量让我们不断前进!</p>
      <div class="teamImgBox">
        <img src="../assets/imgs/home/team1.png" alt="teamImg">
        <img src="../assets/imgs/home/team2.png" alt="teamImg">
        <img src="../assets/imgs/home/team3.png" alt="teamImg">
      </div>
    </div>
  </div>
</template>
<script>
  export default {
      name: "about",
      // SEO优化
      metaInfo () {
          return {
              title: this.pageName,
              meta: [{
                  name: 'title',
                  content: '都灵源链-关于我们-互联网+技术合伙人【官网】'
              },{
                  name: 'keywords',
                  content: '都灵源链，关于我们，小程序商城'
              },{
                  name: 'description',
                  content: '都灵源链【www.torinosrc.com】致力于为企业提供整体软件解决方案与软件服务，专注软件定制、网站设计、品牌设计、互联网营销且用心服务。'
              }],
              link: [{                 // set link
                  rel: 'asstes',
                  href: 'https://assets-cdn.github.com/'
              }]
          }
      },
      data() {
          return {
              pageName: this.$store.state.title,
          }
      },
  }
</script>
<style type="less" scoped>
  .aboutUsBox {
    width:65%;
    margin:0 auto;
    text-align: center;
  }
  .aboutUsBox .title {
    margin:45px auto 15px;
    font-size: 28px;
    color:#000102;
    position: relative;
  }
  .aboutUsBox .title span{
    margin-left:10px;
    color: #c8c9c9;
    font-size:18px;
  }
  .aboutUsBox .title:after {
    display: block;
    content: '';
    position: absolute;
    bottom: -40px;
    left:50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    width:70%;
    height:1px;
    background-color: #d7d7d7;
  }
  .aboutUsBox .subTitle {
    margin:65px auto 50px;
    text-align: left;
    font-size:15px;
    line-height: 30px;
    color: #3c3c3c;
  }
  .aboutUsBox .imgUsBox {
    display: flex;
    justify-content: center;
  }
  .aboutUsBox .imgUsBox img{
    display: inline-block;
    width:370px;
    height:250px;
    overflow: hidden;
    margin-right:20px;
    border-radius: 2px;
    -moz-box-shadow:2px 2px 5px #333333;
    -webkit-box-shadow:2px 2px 5px #333333;
    box-shadow:2px 2px 5px #333333;
  }
  .aboutUsBox .teamImgBox {
    display: flex;
    justify-content: center;
    margin-bottom:40px;
  }
  .aboutUsBox .teamImgBox img{
    display: inline-block;
    width:440px;
    height:280px;
    overflow: hidden;
    margin-right:40px;
    border-radius: 2px;
    -moz-box-shadow:2px 2px 5px #333333;
    -webkit-box-shadow:2px 2px 5px #333333;
    box-shadow:2px 2px 5px #333333;
  }
</style>

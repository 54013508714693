<template>
    <div class="contact">
      <div class="solutionBox">
        <h2 class="title">解决方案</h2>
        <p class="subTitle">眼光高度决定品牌厚度</p>
        <ul class="soluList">
          <li>
            <div class="imgBox">
              <img src="../assets/imgs/home/wxproject.jpg">
            </div>
            <div class="textBox">
              <p class="maText">微信小程序</p>
              <p class="subText">微信小程序</p>
              <p class="text">一种不需要下载安装即可使用的应用，它实现了应用“触手可及”的梦想，用户扫一扫或搜一下即可打开应用。</p>
            </div>
          </li>
          <li>
            <div class="imgBox">
              <img src="../assets/imgs/home/solu3.png">
            </div>
            <div class="textBox">
              <p class="maText">响应式交互网站</p>
              <p class="subText">响应式交互网站</p>
              <p class="text">页面的设计与开发应当根据用户行为以及设备环境进行相应的响应和调整。</p>
            </div>
          </li>
          <li>
            <div class="imgBox">
              <img src="../assets/imgs/home/solu2.png">
            </div>
            <div class="textBox">
              <p class="maText">手机APP开发</p>
              <p class="subText">手机APP开发</p>
              <p class="text">专注于手机应用软件开发与服务。专业设计,精细项目管理,过硬APP开发技术。</p>
            </div>
          </li>

        </ul>
        <h2 class="title">设计方案</h2>
        <p class="subTitle">眼光高度决定品牌厚度</p>
        <ul class="soluList">
          <li>
            <div class="imgBox">
              <img src="../assets/imgs/home/solu1.png">
            </div>
            <div class="textBox">
              <p class="maText">UI界面交互设计</p>
              <p class="subText">UI界面交互设计</p>
              <p class="text">对软件的人机交互、操作逻辑、界面美观的整体设计。</p>
            </div>
          </li>
          <li>
            <div class="imgBox">
              <img src="../assets/imgs/home/solu4.png">
            </div>
            <div class="textBox">
              <p class="maText">手机APP界面设计</p>
              <p class="subText">手机APP界面设计</p>
              <p class="text">帮助客户用UI设计创造产品价值,为不同行业提供产品需求确立,原型设计,交互设计等</p>
            </div>
          </li>
          <li>
            <div class="imgBox">
              <img src="../assets/imgs/home/solu5.png">
            </div>
            <div class="textBox">
              <p class="maText">手机产品交互设计</p>
              <p class="subText">手机产品交互设计</p>
              <p class="text">对于用户产品使用行为的优化设计。让产品易用，有效而让人愉悦的技术。</p>
            </div>
          </li>
        </ul>

      </div>
    </div>
</template>
<script>
    export default {
        name: "solution",
        // SEO优化
        metaInfo () {
            return {
                title: this.pageName,
                meta: [{
                    name: 'title',
                    content: '都灵源链-解决方案-互联网+技术合伙人【官网】'
                },{
                    name: 'keywords',
                    content: '都灵源链，解决方案，小程序商城'
                },{
                    name: 'description',
                    content: '都灵源链【www.torinosrc.com】致力于为企业提供整体软件解决方案与软件服务,专注软件设计、品牌定位、用心服务，帮您实现互联网品牌价值最大化。'
                }],
                link: [{                 // set link
                    rel: 'asstes',
                    href: 'https://assets-cdn.github.com/'
                }]
            }
        },
        data() {
            return {
                pageName: this.$store.state.title,
            }
        },
    }
</script>
<style type="less" scoped>
  .solutionBox {
    width:100%;
    text-align: center;
  }
  .solutionBox .title {
    margin: 45px auto 10px;
    font-size:28px;
    color: #010000;
  }
  .solutionBox .subTitle {
    line-height:30px;
    font-size:14px;
    color: #787878;
    letter-spacing: 4px;
    margin: 0 auto;
  }
  .soluList li {
    display: flex;
    width:60%;
    margin:35px auto;
    padding:35px 55px;
    border:1px solid #000000;
  }
  .soluList li .imgBox {
    /*border:1px solid #000000;*/
    width:372px;
    height:254px;
    overflow: hidden;
    margin-right:150px;
    background-color:#f9f9f9;
  }

  img{
    border-radius: 2px;
    -moz-box-shadow:2px 2px 5px #333333;
    -webkit-box-shadow:2px 2px 5px #333333;
    box-shadow:2px 2px 5px #333333;
  }
  .soluList li .textBox {
    text-align: left;
    width:35%;
  }
  .soluList li .textBox .maText{
    margin-bottom:40px;
    padding-left:20px;
    border-left:3px solid #0454b0;
    letter-spacing: 2px;
    font-size:24px;
    color: #000102;
  }
  .soluList li .textBox .subText {
    margin-bottom:10px;
    font-size:18px;
    color: #555657;
  }
  .soluList li .textBox .text {
    font-size: 15px;
    line-height:25px;
    color: #555657;
  }
</style>

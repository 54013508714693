import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
      title: '都灵源链信息科技',
      isShowFooter: false,
      seq: '1'
  },
  mutations: {
    changeFooterStatus(state, status){
        state.isShowFooter = status;
    }
  },
  actions: {}
});

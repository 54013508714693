<template>
  <div>
    <div class="bgColor">
      <el-carousel :interval="4000" class="carousel">
        <el-carousel-item v-for="item in designBanners"  :key="item" v-bind:style="{background:'url(' + item + ') no-repeat',backgroundSize:'100% 100%'}">
        </el-carousel-item>
      </el-carousel>
      <div class="tabTitleElse">
        <div class="subTab">
          <div style="margin-right: 40px;"  :class="activeItem ? 'textColorBlack':'textColorBlue'" @click="handleAll">全部</div>
          <el-dropdown  @command="handleCommand" trigger="click">
            <span class="el-dropdown-link" :class="!activeItem ? 'textColorBlack':'textColorBlue'" >
              {{activeItem ? selectArray[selectIndex] : '作品分类'}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item,index) in selectArray" :key="index" :command="index" divided>{{item}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!--<el-button type="primary" plain @click="hideMore">返回</el-button>-->
      </div>
    </div>

    <div v-if="!activeItem">
      <div class="designRow">
        <div class="designItem" v-for="(item,index) in design1MoreData" :key="index">
          <img :src="item.designcomputer" style="width: 195px;height:155px"/>
          <div class="placeItem">
            <p style="margin:0 0;">{{item.title}}</p>
          </div>
          <div class="textRow">
            <img src="../assets/imgs/home/designavator.jpg" style="width: 20px;height: 20px;"/>
            <p style="margin:0 10px;">{{item.name}}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="activeItem">
      <div class="designRow">
        <div class="designItem" v-for="(item,index) in design1MoreData" v-show="index == selectIndex" :key="index">
          <img :src="item.designcomputer" style="width: 195px;height:155px"/>
          <div class="placeItem">
            <p style="margin:0 0;">{{item.title}}</p>
          </div>
          <div class="textRow">
            <img src="../assets/imgs/home/designavator.jpg" style="width: 20px;height: 20px;"/>
            <p style="margin:0 10px;">{{item.name}}</p>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>
<script>
  import designBanner from '@/assets/imgs/home/designBanner.jpg';
  import designBanner1 from '@/assets/imgs/home/designBanner1.jpg';
  import designcomputer from '@/assets/imgs/home/designcomputer.jpg'
  import designavator1 from '@/assets/imgs/home/designavator1.jpg'

  import designImg1 from '@/assets/imgs/home/design/design1.jpg'
  import designImg2 from '@/assets/imgs/home/design/design2.png'
  import designImg3 from '@/assets/imgs/home/design/design3.png'
  import designImg4 from '@/assets/imgs/home/design/design4.png'
  import designImg5 from '@/assets/imgs/home/design/design5.png'
  import designImg6 from '@/assets/imgs/home/design/design6.png'
  import designImg7 from '@/assets/imgs/home/design/design7.png'
  import designImg8 from '@/assets/imgs/home/design/design8.png'
  import designImg9 from '@/assets/imgs/home/design/design9.png'
  import designImg10 from '@/assets/imgs/home/design/design10.png'

  export default {
      name: "designMore",
      // SEO优化
      metaInfo () {
          return {
              title: this.pageName,
              meta: [{
                  name: 'title',
                  content: '都灵源链-设计详情-互联网+技术合伙人【官网】'
              },{
                  name: 'keywords',
                  content: '都灵源链，设计详情，小程序商城'
              },{
                  name: 'description',
                  content: '都灵源链【www.torinosrc.com】致力于为企业提供整体软件解决方案与软件服务,专注软件设计、品牌定位、用心服务，帮您实现互联网品牌价值最大化。'
              }],
              link: [{                 // set link
                  rel: 'asstes',
                  href: 'https://assets-cdn.github.com/'
              }]
          }
      },
    data () {
      return {
          pageName: this.$store.state.title,
        designBanners:[
          designBanner,
          designBanner1
        ],
        design1MoreData:[
          {
            "designcomputer":designImg1,
            "title":"婚庆官网设计",
            "name":"唯意"
          },
          {
            "designcomputer":designImg2,
            "title":"鲜花官网设计",
            "name":"唯意"
          },
          {
            "designcomputer":designImg3,
            "title":"服装官网设计",
            "name":"唯意"
          },
          {
            "designcomputer":designImg4,
            "title":"教育官网设计",
            "name":"唯意"
          },
          {
            "designcomputer":designImg5,
            "title":"酒店官网设计",
            "name":"唯意"
          },
          {
            "designcomputer":designImg6,
            "title":"红酒官网设计",
            "name":"唯意"
          },
          {
            "designcomputer":designImg7,
            "title":"家具官网设计",
            "name":"唯意"
          },
          {
            "designcomputer":designImg8,
            "title":"健身官网设计",
            "name":"唯意"
          },
//          {
//            "designcomputer":designImg9,
//            "title":"茶叶官网设计",
//            "name":"唯意"
//          },
//          {
//            "designcomputer":designImg10,
//            "title":"医疗器械官网设计",
//            "name":"唯意"
//          }
        ],
        selectArray:[
          "婚庆",
          "鲜花",
          "服装",
          "教育",
          "酒店",
          "红酒",
          "家具",
          "健身",
//          "茶叶",
//          "医疗器械"
        ],
        selectIndex:0,
        activeItem:false
      }
    },
    methods: {
      handleAll() {
        this.activeItem = false
      },
      handleCommand(index) {
        this.activeItem = true
        this.selectIndex = index
        console.log("index"+index)
      },
    },
    mounted() {

    }
  }
</script>

<style type="less" scoped>
  /*修改下拉框默认样式*/
.el-dropdown {
  font-size:15px;
}
.bgColor {
  background-color: #fff;
}
.textColorBlack {
  color: #606266;
}
.textColorBlue {
  color: #409EFF;
}
.carousel {
  width:60%;
  margin:20px auto;
  height:auto;
}
.tabTitle {
  width:60%;
  height:55px;
  margin:20px auto;
  padding:0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tabTitleElse {
  width:60%;
  height:55px;
  margin:20px auto;
  padding:0 20px;
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  align-items: center;
  color: #606266;
}
.subTab {
  display: flex;

}
.designRow {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width:60%;
  margin:0 auto 20px;
  font-size:14px;
}

.designItem {
  width:23%;
  margin-top:20px;
  /*margin-right:3%;*/
  padding-top:20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.designlastItem {
  margin-right:0;
}
.placeItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height:30px;
}
/*设计师弹窗*/
.textRow {
  width:100%;
  height:40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top:1px solid #f6f6f6;
  padding-left:20px;
}
.leftSign {
  width:60px;
}
.rightText {
  flex:1;
  color: #303133;
}

.moreDesignRow {
  display: flex;
  /*justify-content: space-around;*/
  width:60%;
  margin:0 auto 20px;
  font-size:15px;
}
.dialogBox {
  width:60%;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>

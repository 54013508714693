import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Solution from "./views/Solution.vue";
import Contact from "./views/Contact.vue";
import About from "./views/About.vue";
import Cicadas from "./views/Cicadas.vue";
import Design from "./views/Design.vue";
import DesignMore from "./views/DesignMore.vue";

Vue.use(Router);

export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "home",
            component: Home,
            meta: {
                title: '都灵源链-互联网+技术合伙人【官网】',
                isShowFooter: false,
                seq: '1'
            },
        },
        {
            path: "/solution",
            name: "solution",
            component: Solution,
            meta: {
                title: '都灵源链-解决方案-互联网+技术合伙人【官网】',
                seq: '2'
            }
        },
        {
            path: "/showcicadas",
            name: "cicadas",
            component: Cicadas,
            meta: {
                title: '都灵源链-知了集市-互联网+技术合伙人【官网】',
                seq: '3'
            }
        },
        {
            path: "/about",
            name: "about",
            component: About,
            meta: {
                title: '都灵源链-关于我们-互联网+技术合伙人【官网】',
                seq: '4'
            }
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            // component: () =>
            //     import(/* webpackChunkName: "about" */ "./views/About.vue")
        },
        {
          path: "/contact",
          name: "contact",
          component: Contact,
          meta: {
            title: '都灵源链-联系我们-互联网+技术合伙人【官网】',
            seq: '5'
          }
        },
        {
          path: "/design",
          name: "design",
          component: Design,
          meta: {
            title: '都灵源链-设计作品-互联网+技术合伙人【官网】',
            seq: '6'
          }
        },
        {
          path: "/designMore",
          name: "designMore",
          component: DesignMore,
          meta: {
            title: '都灵源链-设计详情-互联网+技术合伙人【官网】',
            seq: '7'
          }
        }
    ]
});
